/* Digital-gramin-sect */

.Open-Sans-Serif {
    font-family: 'Open Sans', sans-serif;
}

.digiGram-sect {
    width: 100%;
    padding: 3rem 0rem;
}

.Ftitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 50px;
    color: #000000;
    position: relative;
}

.Ftitle::after {
    content: "";
    width: 75px;
    height: 4px;
    background-color: #ff6700;
    position: absolute;
    left: 0px;
    bottom: -15px;
}

p {
    font-size: 0.875rem;
}

@media screen and (max-width: 576px) {
    .Ftitle {
        font-size: 2rem;
    }

    .YWG-list li {
        margin-bottom: 6px;
        font-size: 1.2rem;
    }
}

/* youWillGet-Sect */

.youWGet-sect {
    width: 100%;
    padding: 3rem 0rem;
}

.YWG-card {
    width: 100%;
    background-color: #f8f8f8;
    padding: 2.2rem 3rem 6rem;
    border-radius: 20px;
    box-shadow: 0px 6px 15px 0px rgb(28 28 28 / 1%);
}

.YWG-list {
    width: 100%;
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 2rem;
}

.YWG-list li {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 12px;
    color: #000000;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

/* sevices-sect */

.services-sect {
    width: 100%;
    padding: 3rem 0rem;
}

.services-sect h2 {
    font-size: 2.3rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.services-sect p {
    font-size: 18px;
    font-weight: 500;
}

.franchise {
    margin-top: 50px;
}

.services-card {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: #ffffff;
    padding: 1rem 1.5rem;
    border: 1px solid #ddd;
    overflow: hidden;
}

.franchise-card {
    min-height: 185px;
}

.franchise-card img {
    width: 100px;
}

.franchise-card h4 {
    font-size: 1.2rem;
    font-weight: 700;
    width: 65%;
}

.franchise-card .new-tag {
    width: 35px;
}

/* best-digital-gramin-section */

.best-section {
    width: 100%;
    padding: 3rem 0rem;
}

.best-section h2 {
    font-size: 2.3rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.best-section p {
    font-size: 18px;
    font-weight: 500;
}

/* why-choose-us */

.Wcu-sect .Ftitle {
    max-width: 400px;
    margin: 0px auto 70px;
    font-size: 2.3rem;
    position: relative;
}

.Wcu-sect .Ftitle::after {
    width: 85px;
    left: 40%;
}

.wh-title {
    position: relative;
    display: flex;
    column-gap: 18px;
}

.wh-title span {
    width: 57px;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff6700;
    padding: 13px;
    border-radius: 30px 30px 0px 30px;
}

.wh-cards {
    margin-bottom: 30px;
}

.wh-cards h4 {
    font-size: 1.6rem;
    font-weight: 700;
    max-width: 244px;
    color: #424242;
}

.row .mb-5 {
    margin-bottom: 3rem !important;
}

@media screen and (max-width: 992px) {
    .wh-cards h4 {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 576px) {
    .wh-title {
        margin-bottom: 1.2rem;
    }

    .wh-title span {
        width: 47px;
        height: 46px;
    }

    .wh-cards h4 {
        font-size: 1.2rem;
    }
}

/* franchise-partner-sect */

.frachise-sect {
    width: 100%;
    padding: 3rem 0rem;
    background-color: #f4f4f4;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.title-head h3 {
    color: #545454;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 42px;
}

.title-head h3 span {
    color: #ff6700;
}

.title-head p {
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
}

.title-head h5 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #000000;
    text-align: center;
    margin-top: 35px;
}

.plan-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;
    margin-top: 25px;
}

.plan-cards {
    background-color: #ffffff;
    border-radius: 30px;
    padding: 1.5rem;
    min-width: 270px;
}

.plan-cards h3 {
    color: #ff6700;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 42px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 15px;
}

.plan-cards h4 {
    font-size: 24px;
    text-align: center;
    color: #000000;
    font-weight: 500;
    margin-bottom: 25px;
}

.plan-cards ul {
    margin: 0px;
    padding-left: 24px;
    list-style: none;
}

.plan-cards ul li {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.plan-cards ul li .fa-check {
    color: #00a537;
    font-size: 20px;
}

.plan-cards ul li .fa-x {
    color: rgb(227, 39, 39);
    font-size: 16px;
    font-weight: 350;
}

.btn.joinbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;
    background-color: #00a537;
    border-radius: 30px;
    padding: 10px 32px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 992px) {
    .plan-cards {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 576px) {
    .plan-cards {
        margin-bottom: 30px;
    }
}

/* Benifit-section */

.benefits_sect {
    width: 100%;
    background-color: #ff6700;
    padding: 1rem 0rem 2.5rem;
}

.benefits_sect h3 {
    font-size: 2.2rem;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 20px;
}

.benefits_sect .bt-list {
    margin: 0px;
    margin-bottom: 26px;
    padding-left: 35px;
}

.benefits_sect .bt-list li {
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 6px;
    list-style: disc;
}

.benefits_sect p {
    width: fit-content;
    background-color: #299740;
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
    border-radius: 12px;
    padding: 9px 16px;
    margin-bottom: 10px;
}

/* Required-Docs */

.required-docs {
    width: 100%;
    padding: 1rem 0rem 3rem;
}

.required-docs h3 {
    font-size: 2.5rem;
    color: #000000;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 1.4rem;
    position: relative;
}

.doc-list {
    margin: 0px;
    padding-left: 30px;
}

.duties-title {
    margin-bottom: 70px !important;
}

.duties-title::before {
    content: "";
    width: 80px;
    height: 3px;
    background-color: #ff6700;
    position: absolute;
    left: 0px;
    bottom: -35px;
}

.doc-list li {
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    margin-bottom: 6px;
    list-style: disc;
}

.doc-list .duties-list li {
    font-size: 1.1rem;
}

@media screen and (max-width: 576px) {
    .required-docs h3 {
        font-size: 1.6rem;
    }
}

/* fraNchise-process-section */

.franchise-sect {
    background-color: #f4f4f4;
    padding-top: 2rem;
}

.franchise-sect .Ftitle {
    font-size: 3rem;
}

.franchise-sect h6 {
    font-size: 1rem;
    margin-bottom: 30px;
    color: #424242;
}

.franchise-sect .process-list {
    margin: 0px;
    padding: 0px;
}

.franchise-sect .process-list li {
    list-style: none;
    margin-bottom: 12px;
}

.franchise-sect .process-list h5 {
    font-size: 1.1rem;
    font-weight: 700;
    color: #000000;
}

.franchise-sect .process-list p {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 30px;
    color: #000000;
}

@media screen and (max-width: 576px) {
    .franchise-sect .Ftitle {
        font-size: 2rem;
    }
}

/* getIn-touch-sect */

.getInTouch-sect {
    width: 100%;
    padding: 3rem 0rem;
}

.join-now {
    background-color: #fb8e06;
}

.getInTouch-sect .join-title {
    font-size: 2.5rem;
    border: 5px solid #fff;
    width: fit-content;
    border-radius: 53px;
    padding: 30px 40px;
    margin: 20px auto 50px;
}

.get-head h3 {
    position: relative;
    text-align: center;
    color: #ffffff;

    font-weight: 700;
    text-transform: uppercase;
}

.get-head h3 a {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #0d6efd;
}

.get-head .join-title::after {
    width: 320px;
    bottom: -30px;
}

.get-head h3::after {
    content: "";
    width: 260px;
    height: 4px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translate(-50%);
}

.gtouch-cards span {
    width: 50px;
    height: 50px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #fff;
    display: inline-block;
}

.gtouch-cards {
    display: flex;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
}

.get-head p {
    color: #ffffff;
    font-size: 1.2rem;
}

.gtouch-cards h5 a {
    font-size: 1.2rem;
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width: 576px) {
    .gtouch-cards {
        justify-content: flex-start;
        margin-bottom: 1rem;
        column-gap: 10px;
    }

    .get-head h3 a {
        font-size: 2rem;
    }
}