.Blog-Content {
    padding: 5px 15px 20px;
    background-color: white;
    height: 100%;
    box-shadow: 0px 1px 10px 0px #e1e1e1;
}

.Blog-Content h3 {
    font-size: 1.3rem;
    margin-top: 14px;
    margin-bottom: 10px;
    color: #424242;
}

.Blog-Content h5 {
    font-size: 16px;
    margin-bottom: 15px;
    color: #424242;
}

.Blog-Content p {
    font-size: 15px;
    color: #424242;
}

.Blog-Content h5 {
    line-height: 23px;
    color: #424242;
}

.Blog-Content .BtnHover {
    background-color: #ff6700;
    font-size: 16px;
    margin-top: 10px;
    color: #fff;
    border: none;
    padding: 5px 20px 8px;
    border-radius: 30px;
    transition: all 0.4s ease-out;
}

.Blog-Content .BtnHover:hover {
    background-color: #01589C;
    font-size: 16px;
    margin-top: 10px;
    color: #fff;
    border: none;
    padding: 5px 20px 8px;
    border-radius: 30px;
    transition: all 0.4s ease-out;
}

.Blog-Content:hover {
    padding: 5px 15px 20px;
    background-color: white;
    transform: translateY(-10px);
    transition: 0.5s ease-out;
    box-shadow: 0px 1px 10px 0px #e1e1e1;
}