

.main_services1{
    margin-top: 20px;
}

.main_services2{
    margin-top: 20px;
}

.main_services3{
    margin-top: 20px;
}

.main_services4{
    margin-top: 20px;
}

.main_services5{
    margin-top: 20px;
    margin-bottom: 20px;
}

.main_services_cards_box{
    margin-bottom: 0px;
}

.services_cards{
    background-color: #FF6700;
    border-radius: 10px;
    height: 100%;
    padding: 1.2rem;
    text-align: center;
    margin-bottom: .5rem;
}

.services_cards:hover{
    background-color: #01589c;
    border-radius: 10px;
    padding: 1.2rem;
    text-align: center;
    margin-bottom: .5rem;
}

.services_cards img{
    height: 55px;
    margin-top: 15px;
    cursor: pointer;
}

.services_cards h4{
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 400;
    margin-top: 18px;
    margin-bottom: 10px;
    color: white;
}


@media screen and (max-width:770px) {
    .main_services1{
        margin-top: 20px;
    }

    .main_services2{
        margin-top: 40px;
    }

    .main_services3{
        margin-top: 40px;
    }

    .main_services4{
        margin-top: 40px;
    }

    .main_services5{
        margin-top: 40px;
        margin-bottom: 50px;
    }

    .main_services_cards_box{
        margin-bottom: 25px;
    }

    .services_cards{
        background-color: #FF6700;
        border-radius: 10px;
        height: 100%;
        padding: 13px;
        text-align: center;
        margin-bottom: 1.5rem;
    }
  
}