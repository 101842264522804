.Main-Container {
    padding: 50px;
}

.blog-comman {
    font-family: 'Open Sans', sans-serif;
}

.blog-comman h1 {
    font-size: calc(1.075rem + 1.5vw);
    margin-bottom: .6rem;
    font-weight: 500;
    line-height: 1.2;
}

.blog-comman h5 {
    font-size: 1.25rem;
    margin-bottom: 0.8rem;
    font-weight: 600;
    line-height: 1.2;
}

.blog-comman h3 {
    font-size: 1.45rem;
    margin-bottom: 1rem;
    font-weight: 800;
    line-height: 1.2;
}

.blog-comman b {
    font-weight: 800;
}

.blog-comman p {
    font-size: .875rem;
    margin-bottom: 1rem;
    margin-top: .2rem;
}

.blog-comman li {
    font-size: .925rem;
    line-height: 1.8;
}

.blog-comman h2 {
    font-size: calc(1.075rem + .9vw);
    margin-bottom: 0.7rem;
    margin-top: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
}

.blog-comman h6 {
    font-size: 1.01rem;
    margin-bottom: 0.9rem;
    line-height: 1.5;
}

@media screen and (max-width:770px) {
    .Main-Container {
        padding: 20px;
    }

    .blog-comman h5 {
        font-size: 1rem;
        margin-bottom: 0.8rem;
        font-weight: 600;
        margin-top: 15px;
        line-height: 1.2;
    }
}