.list-group-item {
    padding: 14px 15px;
}

.Legal-Docs-Hover {
    color: #424242;
}

.Legal-Docs-Hover:hover {
    color: #FF6700;
}