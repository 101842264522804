
.Open-Sans-Serif {
    font-family: 'Open Sans', sans-serif;
}

.InterBoldFontFamily {
    font-family: 'Inter', sans-serif;
}

.abt-sect {
    width: 100%;
    background-image: url(../Img//Home2bg.png);
    background-position: top center;
    background-size: cover;
    display: block;
    background-repeat: no-repeat;
}

.abt-heading h1 {
    font-size: 2rem;
    margin-bottom: 1.2rem;
    margin-top: 10px;
    color: #000000;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    
}

.abt-heading .para-text p {
    font-size: 0.965rem;
}

.our-vision1 {
    width: 100%;
    /* min-height: 330px; */
    padding: 35px 35px;
    background-color: #ff6700;
    border-radius: 15px;
    margin-bottom: 30px;
}

.our-vision1 p {
    color: #ffffff;
}

.our-vision1 h3 {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #ffffff;
    text-transform: uppercase;
}

.our-vision1 ul li {
    color: #ffffff;
    font-size: 0.918rem;
    margin-bottom: 4px;
    list-style-type: disc;
}