.Main_Container {
    padding: 25px;
    font-family: 'Open Sans', sans-serif;
}

.Main_Title {
    font-size: 1.8rem;
    color: black;
    margin-bottom: 0px;
    padding-left: 18px;
    font-family: 'Inter', sans-serif;
}

.Comman_Img {
    height: 4rem;
    background-color: transparent;
}

.Main_Title_Bottom {
    padding: 25px;
}

.Main_Title_Bottom h2 {
    font-size: 1.3rem;
    color: black;
    margin-bottom: 15px;
    margin-top: 1.3rem;
    font-family: 'Inter', sans-serif;
}

.Main_Title_Bottom h3 {
    font-size: 1.1rem;
    color: black;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
}

.Main_Title_Bottom h5 {
    font-size: 1.15rem;
    color: black;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
}

.Main_Title_Bottom h6 {
    font-size: 1rem;
    color: black;
    font-weight: 400;
    margin-bottom: 15px;
    font-family: 'Inter', sans-serif;
}

.Main_Title_Bottom p {
    font-size: 1rem;
    color: black;
    font-weight: 400;
}

.Main_Title_Bottom ul li {
    font-size: .938rem;
    color: black;
    line-height: 30px;
    font-weight: 400;
}


.Bc-services {
    width: 100%;
    background-image: url("../Img/FooterBG.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Bc-service-title {
    font-size: 1.7rem;
    padding-left: 12px;
    margin-bottom: 0px;
    font-weight: 700;
}

.article-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.article-content p {
    font-size: 0.938rem;
    font-weight: 400;
}

.article-content h2 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1.4rem;
    margin-bottom: 10px;
    padding-left: 0.8rem;
}

.article-content h3 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1.4rem;
    margin-bottom: 10px;
}

.article-content .bc-service-list {
    padding-left: 1.8rem !important;
}

.bc-service-list li {
    font-weight: 400;
    font-size: 0.938rem;
    line-height: 24px;
}


.Footer-SubLinks p{
    font-size: 16px;
    line-height: 25px;
}

.Footer-SubLinks h2{
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 2.2rem;
    margin-bottom: 11px;
}



@media screen and (max-width:770px) {
    .Main_Container {
        padding: 20px;
    }

    .Main_Title {
        font-size: 1.3rem;
        color: black;
        font-weight: 700;
        padding-left: 20px;
        letter-spacing: 1px;
        margin-top: 10px;
    }

    .Comman_Img {
        height: 6rem;
        margin-top: 10px;
        background-color: transparent;
    }

    .Main_Title_Bottom {
        margin-top: 8px;
        padding: 0px;
    }

    .Main_Title_Bottom p {
        font-size: 19px;
        color: black;
        font-weight: 400;
    }

    .Main_Title_Bottom ul li {
        font-size: 20px;
        color: black;
        line-height: 32px;
        font-weight: 400;
    }

}