.Open-Sans-Serif {
    font-family: 'Open Sans', sans-serif;
}

/* .navbar.fixed{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1950;
    box-shadow: rgb(72 73 121 / 18%) 0px 0px 15px 0px;
    animation-name: top;
    animation-duration: 1s;
} */

.Header-Size {
    overflow: hidden;
    background-color: transparent;
    font-size: 0.938rem;
    padding: 15px;
    font-weight: 400;
}

.Header-Size:hover {
    background-color: #003573;
}


.bottom-header {
    width: calc(100% - 100px);
    margin-left: 80px;
    display: flex;
    justify-content: space-between;
    padding: 3px 0px;
}

.login-regs {
    column-gap: 25px;
    align-items: center;
}

.login-regs ul {
    min-width: 200px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    column-gap: 5px;
    background-color: #00A537;
    border-radius: 30px 0px 0px 30px;
}

.Mobile_Number {
    font-size: 0.875rem;
}

.Mobile_Email {
    font-size: 0.875rem;
}

@media screen and (max-width:810px) {
    .Header-Size {
        font-size: 1rem;
        padding: 8px;
    }

    .bottom-header {
        width: calc(100% - 100px);
        margin-left: 0px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
    }

    .login-regs {
        column-gap: 25px;
        justify-content: center;
    }

    .login-regs ul {
        /* width: 0%; */
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        background-color: #00A537;
        padding: 0px 0px;
        border-radius: 30px 0px 0px 30px;
    }
}

@media screen and (max-width:767px) {
    .Header-Size {
        font-size: 1rem;
        padding: 8px;
    }

    .bottom-header {
        width: 100%;
        margin-top: 10px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .login-regs {
        width: 100%;
        column-gap: 30px;
        justify-content: center;
    }

    .login-regs ul {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        font-size: .875rem;
        background-color: #00A537;
        padding: 0px 0px;
        border-radius: 30px 0px 0px 30px;
    }

    .Mobile_Number {
        font-size: 1.1rem;
        margin-bottom: 12px;
        letter-spacing: 1px;
    }

    .Mobile_Email {
        font-size: 1.1em;
        letter-spacing: 1px;
        margin-bottom: 12px;
    }
}