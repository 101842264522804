.ContacBG_Size {
    height: 450px;
    max-width: 100%;
}

.SendButton {
    background-color: #FD8E03;
    color: white;
    border-radius: 30px;
    height: 45px;
    border: none;
}

.SendButton:hover {
    background-color: #0b5ed7;
}

@media screen and (max-width:767px) {
    .ContacBG_Size {
        height: 140px;
        max-width: 100%;
    }
}