.Open-Sans-Serif {
    font-family: 'Open Sans', sans-serif;
}


.main-footer {
    width: 100%;
    background-image: url(../Img/FooterBG.png);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3rem 0rem;
}

.ftLogo {
    column-gap: 15px;
}

.top-footer {
    background-color: #ffffff;
    padding: 1.2rem;
    border-radius: 40px;
}

.footer-logo_img {
    width: 300px;
}

.top-footer h6 {
    margin-bottom: 0px;
    margin-top: 8px;
    padding-top: 0px;
    padding-left: 26px;
    font-size: 1.1rem;
    border-left: 1px solid #444;
}

.social-sites {
    display: flex;
    column-gap: 20px;
    padding-top: 10px;
}

.social-sites h5 {
    font-size: 1.3rem;
    font-weight: 500;
}

.social-sites ul {
    column-gap: 12px;
    margin-bottom: 0px;
}

.social-sites ul li {
    list-style: none;
}

.social-sites img {
    width: 30px;
}

/* Bottom-Footer */

.bottom-footer {
    padding-top: 30px;
}

.bottom-footer h4 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 700;
}

.bottom-footer p a {
    color: #000000;
    font-style: italic;
}

.footer-linkes-cards h3 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 12px;
    line-height: 1.3;
    margin-top: 1.4rem;
}

.footer-linkes-cards ul {
    margin: 0px;
    padding: 0px;
}

.footer-linkes-cards ul li {
    margin-bottom: 2px;
    list-style: none;
}

.links-row {
    display: flex;
    column-gap: 40px;
}

a {
    color: #000000;
    font-size: 0.875rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 576px) {

    .main-footer{
        padding: 1rem 0rem;
    }
    

    .footer-linkes-cards ul {
        width: 50%;
    }

    .links-row {
        display: grid;
        grid-row-gap: 8px;
        grid-template-columns: repeat(2, 1fr);
    }

    .top-footer h6 {
        margin-bottom: 0px;
        margin-top: 8px;
        padding-top: 0px;
        padding-left: 6px;
        font-size: 0.911rem;
        border-left: 1px solid #444;
    }

    .footer-logo_img {
        width: 180px;
    }

    .social-sites h5 {
        font-size: 1.1rem;
        font-weight: 500;
    }
}