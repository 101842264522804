.Title_Silder {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 1.5rem;
    color: #000000;
}

.main_size {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 250px;
    padding: 10px;
}

.ImgSize {
    width: 170px;
    height: 70px;
}

@media screen and (max-width:767px) {
    .main_size {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35px;
        height: 250px;
        width: 100%;
        padding: 20px;
    }

    .ImgSize {
        width: 100%;
        height: 100%;
    }

    .Title_Silder {
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 1.5rem;
        color: #000000;
    }

}