.Pdf-size {
    height: 100vh;
    width: 100%;
}

@media screen and (max-width:770px) {

    .Pdf-size {
        height: 600px;
        width: 100%;
    }
}