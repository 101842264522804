.Apply_For_Job {
    padding: 20px;
}

.Apply_For_Job h2 {
    font-size: 2.325rem;
    font-weight: 700;
    color: black;
    margin-top: 30px;
    margin-bottom: 2rem;
}

.Apply_For_Job h3 {
    font-size: 1.4rem;
    font-weight: 700;
    color: black;
    margin-top: 1.5rem;
    margin-bottom: 1.7rem;
}

.Apply_For_Job p {
    font-size: 1.125rem;
    font-weight: 600;
}

.applybtn{
    font-size: 1.1rem;
    font-weight: 600;
    background-color: #FF6700;
    color: white;
    border-radius: 30px;
    padding: 15px 25px 15px;
    margin-top: 1rem;
    border: none;
}



@media screen and (max-width:767px) {
    .Apply_For_Job {
        padding: 10px;
    }

    .Apply_For_Job h2 {
        font-size: 1.925rem;
        font-weight: 700;
        color: black;
        margin-top: 30px;
        margin-bottom: 1rem;
    }
}