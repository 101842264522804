.Open-Sans-SerifBOLD {
    font-family: 'Open Sans', sans-serif;
}

.Open-Sans-Serif {
    font-family: 'Open Sans', sans-serif;
}

.InterBoldFontFamily {
    font-family: 'Inter', sans-serif;
}

.Buttonn {
    width: fit-content;
    margin: 0 auto;
}

.Buttonn2{
    background-color: #FF6700;
    border-radius: 30px;
    font-size: 1.525rem;
    border: none;
    padding: 1rem 1.6rem;
}

.Banking_Section_Common{
    background-color: #FF6700;
    border: none;
    color: white;
    border-radius: 30px;
    font-size: 0.825rem;
    padding: 7px 13px;
}

.Banking_Section_Common:hover{
    background-color: #002047;
    border: none;
    color: white;
    border-radius: 30px;
    font-size: 0.825rem;
    padding: 7px 13px;
}

.IndiaIMG {
    margin-bottom: -26px;
}

.retdt-list li {
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 10px;
    border-radius: 40px;
    padding: 16px 30px;
    list-style: none;
    /* display: flex; */
    align-items: center;
    column-gap: 12px;
}

.map-height {
    height: 450px;
    margin-top: 20px;
}

.business-card {
    background-color: #fff;
    border-radius: 70px;
    padding: 1rem 1rem;
    column-gap: 15px;
}

.become_retailer_top{
    background-color: #fff;
    border-radius: 1rem;
    padding: 2px 15px 20px 15px;
}

.become_retailer{
    background-color: #FF6700;
    border-radius: 1rem;
    padding: 1.6rem 1.6rem;
}

.become_retailerh2{
    font-size: 3.8rem;
    color: #fff;
    font-weight: 800;
}

.no_need{
    font-size: 1.5rem;
    color: black;
    font-weight: 500;
    line-height: 50px;
}

.Start_Your_Business{
    font-size: 2.6rem;
    font-weight: 800;
}

.aadaharPanImg{
    width: 220px;
}

.plus{
    margin-left: 1px;
}

.get-card{
    width: 70%;
    line-height: 40px;
}

.get-list{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.unl-creation{
    background-color: #014274;
    padding: 2rem 2rem;
    border-radius: 1.2rem;
}

.unl-creation ul{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.unl-creation ul li{
    border-radius: 30px;
    border: 4px solid #fff;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 10px;
    background-color: #FF6700;
    width: fit-content;
    padding: 10px 20px;
    color: white;
}

.digital-seva{
    width: 100%;
    background-image: linear-gradient(rgba(0, 165, 55, 0.8), rgba(0, 165, 55, 0.8)), url(../Img/Background3.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    overflow: hidden;
}

.title-4{
    text-align: center;
    background-color: #FF6700;
    padding: 1.4rem 1.4rem;
    font-weight: 800;
    width: fit-content;
    margin: 10px auto 40px;
    font-size: 2.5rem;
}

.digitalcontent{
    margin: 20px auto;
}

.digitalcontent h6{
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
}

.serviceIMG{
    width: 100%;
    height: 60rem;
    margin-top: 150px;
    padding: 20px;
}

.Years5{
    background-color: #00A537;
    color: white;
    padding: 20px 35px 18px 20px;
    
}

.India1{
    background-color: #FF6700;
    color: white;
    padding: 20px 35px 18px 20px;
}

.year5India{
    font-size: 1.4rem;
    font-weight: 800;
}

.district{
    font-size: 4.2rem;
}


@media screen and (max-width:1200px) {
    .map-height {
        height: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width:1000px) {
    .map-height {
        height: 100%;
        margin-top: 70px;
    }
    
}

@media screen and (max-width:770px) {
   
    .serviceIMG{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50rem;
        width: 300px;
        margin: 0 auto;
        margin-top: 10px;
        padding: 20px;
    }

    .Buttonn2{
        background-color: #FF6700;
        border-radius: 30px;
        font-size: 1.1rem;
        margin-top: 20px;
        border: none;
        padding: 1rem 1.6rem;
    
    }

    .year5India{
        font-size: 1rem;
    }

    .district{
        font-size: 2.3rem;
        padding-top: 30px;
    }

    .title-4{
        font-size: 1.6rem;
        margin-top: 36px;
    }

    .aadaharPanImg{
        width: 100%;
    }

    .plus{
        text-align: center;
    }

    .business-card {
        background-color: #fff;
        border-radius: 0px;
        padding: 10px;
    }

    .become_retailer{
        background-color: #FF6700;
        border-radius: 0px;
        padding: 15px;
    }

    .become_retailer_top{
        background-color: #fff;
        border-radius: 0px;
        padding: 0px;
        
    }

    .become_retailerh2{
        font-size: 2.5rem;
        margin-bottom: 20px;
        color: #fff;
        font-weight: 800;
    }

    .no_need{
        font-size: 1.3rem;
        color: black;
        font-weight: 400;
        line-height: 50px;
    }

    .Start_Your_Business{
        font-size: 1.6rem;
        margin-top: 20px;
        font-weight: 800;
    }

    .get-card{
        width: 100%;
        line-height: 40px;
    }
  
}